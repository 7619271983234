<template>
  <div class="popup_wrap" style="width:800px;">
    <button class="layer_close" @click.prevent="$emit('close')">close</button>
    <div class="popup_cont">
      <h1 class="page_title">{{ $t('msg.CMBA100.00320') }}</h1>
      <div class="content_box mt10">
        <table class="tbl_row">
          <colgroup>
            <col style="width:20%">
            <col style="width:30%">
            <col style="width:20%">
            <col style="width:30%">
          </colgroup>
          <tbody>
            <tr>
              <th scope="row">{{ $t('msg.CMBA100.00322') }}</th>
              <td>
                <e-input-number
                  id="bzrgNo"
                  v-model="param.bzrgNo"
                  class="wid130"
                  @keyup.enter="searchData()"
                >
                </e-input-number>
                <button type="button" class="sm button ml2" @click="searchData">{{ $t('msg.CMBA100.00323') }}</button>
              </td>
              <th scope="row">{{ $t('msg.CMBA100.00336') }}</th>
              <td>
                <input type="text" class="wid130" v-model="param.bzrgNM" @keyup.enter="searchData"><button type="button" class="sm button ml2" @click="searchData">{{ $t('msg.CMBA100.00323') }}</button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="content_box">
        <div id="realgrid_pop" style="width: 100%; height: 400px"></div>
      </div>
      <div class="mt10 text_center">
        <a class="button gray lg" href="#" @click.prevent="$emit('close')">{{ $t('msg.CMBA100.00261') }}</a>
      </div>
    </div>
  </div>
</template>

<script>
import { GridView, LocalDataProvider } from 'realgrid'
import BusinessNumber from '@/api/rest/user/businessNumber'
import EInputNumber from '@/components/common/EInputNumber'
let gridView = GridView
let provider = LocalDataProvider

const fields = [
  { fieldName: 'bzrgNo', dataType: 'text' },
  { fieldName: 'cstNo', dataType: 'text' },
  { fieldName: 'bsnNm', dataType: 'text' },
  { fieldName: 'addr', dataType: 'text' },
  { fieldName: 'faxNo', dataType: 'text' },
  { fieldName: 'telNo', dataType: 'text' },
  { fieldName: 'select', dataType: 'text' }
]

const columns = [
  {
    name: 'bzrgNo',
    fieldName: 'bzrgNo',
    header: { text: app.$t('msg.MYIN040G010.022') }, // 사업자등록번호
    width: 80
  },
  {
    name: 'cstNo',
    fieldName: 'cstNo',
    header: { text: app.$t('art.CMATK136') }, // 화주코드
    width: 60
  },
  // {
  //   name: 'cstNo',
  //   fieldName: 'cstNo',
  //   header: { text: '회사코드' },
  //   width: 100
  // },
  {
    name: 'bsnNm',
    fieldName: 'bsnNm',
    header: { text: app.$t('msg.CSBL100_P1.011') }, // 회사명
    width: 120
  },
  { name: 'addr', fieldName: 'addr', header: { text: app.$t('msg.CMBA100.00327') }, width: 199 }, // 회사주소
  { name: 'faxNo', fieldName: 'faxNo', header: { text: app.$t('msg.CMBA100.00108') }, width: 80 }, // 전화번호
  { name: 'telNo', fieldName: 'telNo', header: { text: app.$t('msg.CMBA100.00109') }, width: 80 }, // 팩스번호
  {
    name: 'select',
    fieldName: 'select',
    header: { text: app.$t('msg.ONIM070P020.002') }, // 선택
    width: '70',
    renderer: {
      type: 'html',
      callback: function (grid, cell, w, h) {
        //if (provider.getValue(cell.index.dataRow, 'apvStsCd') === '03' && provider.getValue(cell.index.dataRow, 'appRk') === '1') {
          return '<span class="gray button sm">' + app.$t('msg.ONIM070P020.002') + '</span>'
        //} else {
          //return ''
        //}
      }
    }
  }
  ]

export default {
  name: 'BusinessNumberPop',
  components: {
    EInputNumber
  },
  props: {
    businessInfo: {
      type: Object,
      default: function () {
        return {
          bzrgNo: '',
          bsnNm: ''
        }
      }
    }
  },
  data: function () {
    return {
      param: {
        bzrgNo: ''
      },
      list: []
    }
  },
  computed: {
  },
  watch: {
    businessInfo: {
      handler () {
        this.setParams()
        this.searchData()
      },
      deep: true
    }
  },
  created () {
    this.pageInit()
  },
  mounted: function () {
    const $vm = this
    provider = new LocalDataProvider(true)
    gridView = new GridView('realgrid_pop')
    gridView.setDataSource(provider)
    gridView.setStateBar({
      visible: false
    })
    gridView.setCheckBar({
      visible: false
    })
    gridView.footers.visible = false
    // 그리드 필드 생성
    provider.setFields(fields)
    gridView.setColumns(columns)

    gridView.onCellItemClicked = function (grid, index, clickData) {
      if (gridView.getCurrent().fieldName === 'select') {
        $vm.$emit('close', $vm.list[index.dataRow])
      }
    }
    //alert(gridView.getColumnProperty('faxNo', 'header').text)
    //언어적용
    //gridView.setColumnProperty('bzrgNo', 'header', gridView.getColumnProperty('bzrgNo', 'header').text = this.$t('msg.CMBA100.0018'))
    //gridView.setColumnProperty('cstNo', 'header', gridView.getColumnProperty('cstNo', 'header').text = this.$t('msg.CMBA100.00325'))
    gridView.setColumnProperty('bsnNm', 'header', gridView.getColumnProperty('bsnNm', 'header').text = this.$t('msg.CMBA100.00326'))
    gridView.setColumnProperty('addr', 'header', gridView.getColumnProperty('addr', 'header').text = this.$t('msg.CMBA100.00327'))
    gridView.setColumnProperty('faxNo', 'header', gridView.getColumnProperty('faxNo', 'header').text = this.$t('msg.CMBA100.00108'))
    gridView.setColumnProperty('telNo', 'header', gridView.getColumnProperty('telNo', 'header').text = this.$t('msg.CMBA100.00329'))
  },
  methods: {
    pageInit () {
      const $vm = this
      // this.param.bzrgNo = this.businessInfo.bzrgNo
      this.setParams()
      $vm.searchData()
    },
    setParams () {
      console.log('>>>>>> ', this.businessInfo)
      // this.param.bzrgNo = this.businessInfo.bzrgNo
      // this.param.bzrgNM = this.businessInfo.bsnNm
      const txt = this.$_.trim(this.businessInfo.bzrgNo)
      const re = new RegExp('^[0-9]+$', 'gi')
      if (re.test(txt)) {
        this.param.bzrgNo = txt
        this.param.bzrgNM = ''
      } else {
        this.param.bzrgNo = ''
        this.param.bzrgNM = txt
      }
    },
    searchData: function () {
      BusinessNumber.getBusinessNumber(this.param).then(response => {
        //console.log(JSON.stringify(response.data.proxyList))
        this.list = response.data.proxyList
        provider.setRows(this.list)
      }).catch(err => {
        console.log(err)
      })
    },
    layerClose: function () {
    console.log('================layerClose=================')
      $('.popup_dim').fadeOut()
      $('body,html').removeAttr('style')
      return false
    }
  }
}
</script>

 <style scoped>
/*.left-column {
  text-align: left;
}*/
 </style>
